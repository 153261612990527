import React, { useState } from "react";
import styled from "styled-components";
import CrispArticle from "./CrispArticle";

interface RightArrowProps {
  itemName: string;
}

const CrispArticleCategory: React.FC<RightArrowProps> = (props: RightArrowProps) => {
  const [isArrowRotated, setIsArrowRotated] = useState(false);
  const rotateArrow = () => {
    setIsArrowRotated(!isArrowRotated);
  };

  const SignUp = () => {
    return (
      <div>
        <CrispArticle articleId="v79a81" text="How does the online activation work?" />
        <CrispArticle articleId="srg8fx" text="How can a traveler who has just arrived in Canada purchase a SIM card?" />
        <CrispArticle articleId="1nkix9k" text="Is it possible to schedule the activation date of the SIM card?" />
        <CrispArticle articleId="uwahhi" text="Is it possible to purchase a SIM card outside of Canada?" />
        <CrispArticle articleId="17wi7eg" text="What plans are available on PhoneBox?" />
        <CrispArticle articleId="11pau8" text="How does a Monthly Plan work?" />
        <CrispArticle articleId="urn3qr" text="How does a Prepaid Plan work?" />
        <CrispArticle articleId="17jpm35" text="How do I start using my PhoneBox SIM card?" />
      </div>
    );
  };

  const AccountChanges = () => {
    return (
      <div>
        <CrispArticle articleId="1urhbpa" text="Does PhoneBox charge a fee to change my plan?" />
        <CrispArticle articleId="12xe2yw" text="Is it possible to change the plan anytime?" />
        <CrispArticle articleId="bhe70u" text="Can I upgrade or downgrade my plan?" />
        <CrispArticle articleId="5kdy0h" text="How can I change my plan from prepaid to monthly?" />
        <CrispArticle articleId="1egd15e" text="How can I change my plan from monthly to prepaid?" />
      </div>
    );
  };

  const LeavingPortOutSuspension = () => {
    return (
      <div>
        <CrispArticle articleId="1iuzl9n" text="How do I terminate my account?" />
        <CrispArticle articleId="19m0j9q" text="Can I schedule my account termination date?" />
        <CrispArticle articleId="rj0900" text="Will I get a refund for unused services when I terminate my account?" />
        <CrispArticle articleId="ad7fca" text="When will I get my refund after terminating my account?" />
        <CrispArticle articleId="15izugi" text="How do I transfer my phone number to a different mobile provider?" />
      </div>
    );
  };

  const GeneralInformation = () => {
    return (
      <div>
        <CrispArticle articleId="yjtldc" text="When will PhoneBox deliver a SIM card?" />
        <CrispArticle articleId="1xbumon" text="Which plans are available at PhoneBox?" />
        <CrispArticle articleId="1d8q5gn" text="How good is the PhoneBox network coverage in Canada?" />
        <CrispArticle articleId="fom8xu" text="Why should I choose PhoneBox over other mobile providers?" />
        <CrispArticle articleId="85hmjl" text="PhoneBox plans have a lock-in contract?" />
      </div>
    );
  };

  const ComplaintsAndFeedback = () => {
    return (
      <div>
        <CrispArticle articleId="ekcciy" text="Why have I not received my SIM card yet?" />
        <CrispArticle articleId="okmv45" text="How can I track my SIM card delivery?" />
        {/* <CrispArticle articleId="43" text="The online activation didn't work for my US plan. What should I do?" /> */}
        <CrispArticle articleId="1uez7ba" text="Why is my SIM card delivery delayed?" />
        <CrispArticle articleId="eyfxwf" text="Why is no one responding to my emails?" />
      </div>
    );
  };

  const MultilineAccounts = () => {
    return (
      <div>
        <CrispArticle articleId="qc7zgu" text="What are Multiline Accounts?" />
        <CrispArticle articleId="1ei36rx" text="What are the benefits of using a Multi-lining account function?" />
        <CrispArticle articleId="206eg4" text="How do I add a line on my online account using the multi-lining option?" />
        <CrispArticle articleId="1yp8th9" text="How to remove a line on your online account under the Multi-lining option?" />
      </div>
    );
  };

  const Shipping = () => {
    return (
      <div>
        <CrispArticle articleId="3u1wxh" text="Does PhoneBox offer shipping of SIM cards?" />
      </div>
    );
  };

  const NetworkTechnicalSupport = () => {
    return (
      <div>
        <CrispArticle articleId="1c7l0is" text="My phone shows 'No Service'." />
        <CrispArticle articleId="obxapm" text="I can't make outgoing calls." />
        <CrispArticle articleId="1xcikkb" text="I can't receive calls" />
        <CrispArticle articleId="kwxoz6" text="I can't send text messages" />
        <CrispArticle articleId="135olqo" text="Why can't I receive text messages?" />
      </div>
    );
  };

  const BillingInquiry = () => {
    return (
      <div>
        <CrispArticle articleId="1rm53hz" text="Is it possible to set auto-extension for my prepaid plan?" />
        <CrispArticle articleId="hv4it5" text="What are the payment methods for prepaid plans?" />
        <CrispArticle articleId="pb40mf" text="What are the payment methods for monthly plans?" />
        <CrispArticle articleId="12aqtx1" text="What is the billing cycle for prepaid plans?" />
        <CrispArticle articleId="pb40mf" text="What are the payment methods for monthly plans?" />
      </div>
    );
  };

  const PlansRoamingPromotion = () => {
    return (
      <div>
        <CrispArticle articleId="1y0iy53" text="What is a Vacation Plan and how does it work?" />
        <CrispArticle articleId="1qmv2gc" text="What is the difference between prepaid and monthly accounts?" />
        <CrispArticle articleId="np3r5p" text="Can I extend my vacation plan?" />
        <CrispArticle articleId="nqnrkp" text="I forgot to extend my prepaid plan before term ending date. How can I make a payment?" />
        <CrispArticle articleId="1f01a88" text="Do I get a notification when my prepaid plan is about to expire?" />
        <CrispArticle articleId="d9iq1r" text="Will I get a notification if I exceed my data limit?" />
        <CrispArticle articleId="7yge1d" text="Can I still receive calls and SMS after my prepaid plan has expired?" />
        <CrispArticle articleId="10lec7p" text="How do I add more data to my prepaid plan?" />
        <CrispArticle articleId="hk3tuw" text="Does a prepaid line expire?" />
      </div>
    );
  };

  const Esim = () => {
    return (
      <div>
        <CrispArticle articleId="qx8p3v" text="What is eSIM?" />
        <CrispArticle articleId="1mj0l56" text="How does eSIM activation work?" />
        <CrispArticle articleId="yp2nff" text="What plans are available with eSIM at PhoneBox?" />
        <CrispArticle articleId="k789oq" text="Are all devices compatible with eSIM?" />
      </div>
    );
  };

  const PortIn = () => {
    return (
      <div>
        <CrispArticle articleId="14u6f9e" text="Can I port in my phone number from my previous mobile carrier after I already have my PhoneBox's account activated?" />
        <CrispArticle articleId="d8spwc" text="Can I port-in a landline phone number to PhoneBox?" />
      </div>
    );
  };

  const BeforeSigningUp = () => {
    return (
      <div>
        <CrispArticle articleId="1gfq3un" text="How will I know my Phone number?" />
        <CrispArticle articleId="54te1c" text="Can I change my service's start date with PhoneBox?" />
        <CrispArticle articleId="jerkdp" text="Is my device compatible with PhoneBox network?" />
        <CrispArticle articleId="10jjr6p" text="What is data overage fee and how does it work?" />
        <CrispArticle articleId="zdv1hc" text="Can I sign-up for a PhoneBox plan if I am under 18 years old?" />
        <CrispArticle articleId="h60nr0" text="Can I choose my phone number when I sign up?" />
      </div>
    );
  };
  const USASimCards = () => {
    return (
      <div>
        <CrispArticle articleId="jq0qsy" text="Can I use an international credit card to pay for a US phone number?" />
        <CrispArticle articleId="90p5xe" text="Can I pay for the US phone number in cash?" />
        <CrispArticle articleId="1e3hed1" text="Do I have to contact Phonebox to terminate a US plan?" />
        <CrispArticle articleId="uzyz6e" text="How good is the Phonebox network coverage in the US?" />
        <CrispArticle articleId="1k5pex7" text="What is PhoneBox network speed in the US?" />
        <CrispArticle articleId="1j7t3lo" text="Do PhoneBox USA plans include international calling services?" />
      </div>
    );
  };
  return (
    <div>
      <OuterBar onClick={() => rotateArrow()}>
        <Text style={{ fontWeight: 450 }}>
          {props.itemName}
        </Text>
        <MyImage
          src="/images/support/right-arrow.png"
          isArrowRotated={isArrowRotated}
          height={25}
          width={25}
          alt="right-arrow"
        />
      </OuterBar>
      {props.itemName === "Sign Up" && isArrowRotated ? (
        <SignUp />
      ) : props.itemName === "Account Changes" && isArrowRotated ? (
        <AccountChanges />
      ) : props.itemName === "Leaving/Port Out/Suspension" && isArrowRotated ? (
        <LeavingPortOutSuspension />
      ) : props.itemName === "General Information About PhoneBox" &&
        isArrowRotated ? (
        <GeneralInformation />
      ) : props.itemName === "Complaints and Feedback" && isArrowRotated ? (
        <ComplaintsAndFeedback />
      ) : props.itemName === "Multiline Accounts" && isArrowRotated ? (
        <MultilineAccounts />
      ) : props.itemName === "Shipping" && isArrowRotated ? (
        <Shipping />
      ) : props.itemName === "Network/Technical Support" && isArrowRotated ? (
        <NetworkTechnicalSupport />
      ) : props.itemName === "Billing Inquiry" && isArrowRotated ? (
        <BillingInquiry />
      ) : props.itemName === "Plans/Roaming/Promotion" && isArrowRotated ? (
        <PlansRoamingPromotion />
      ) : props.itemName === "eSIM" && isArrowRotated ? (
        <Esim />
      ) : props.itemName === "Port In" && isArrowRotated ? (
        <PortIn />
      ) : props.itemName === "Before Signing Up" && isArrowRotated ? (
        <BeforeSigningUp />
      ) : props.itemName === "USA SIM Cards" && isArrowRotated ? (
        <USASimCards />
      ) : null}
    </div>
  );
};


const BAR_LENGTH = "80%";

const MyImage = styled.img<{ isArrowRotated: boolean }>`
  display: block;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;

  -webkit-transform: ${(props) =>
    props.isArrowRotated ? "rotate(90deg)" : "rotate(0deg)"};
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
`;

const OuterBar = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  width: ${BAR_LENGTH};
  padding: 10px 20px;
  margin: 10px 0px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0px;
  }
  :hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 32em;
  }
  @media (max-width: 620px) {
    width: 30em;
  }
  @media (max-width: 560px) {
    width: 28em;
  }
  @media (max-width: 520px) {
    width: 26em;
  }
  @media (max-width: 480px) {
    width: 24em;
  }
  @media (max-width: 440px) {
    width: 22em;
  }

  @media (max-width: 390px) {
    width: 20rem;
  }
`;

const Text = styled.p``;

export default CrispArticleCategory;