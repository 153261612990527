import PageLayout from "../components/PageLayout";
import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "gatsby-plugin-react-i18next";

export default function homeInternet() {
  return (
    <PageLayout showPartnerNav>
      <Helmet>
        {/* Breakpoint is 1200px to match with old style where it switch to mobile version when width < 1200px */}
        <style>{`
          body:has(section#home-internet-coming-soon) div[class^="PageLayout__ContentWrapper"] {
            padding: 0 0 0 0 !important;
          }
          @media only screen and (max-width: 1200px) {
            #home-internet-coming-soon {
              background: none !important;
              padding-top: 100px;
            }
          }
        `}</style>
      </Helmet>
      <title>Home Internet | PhoneBox</title>
      <section
        id="home-internet-coming-soon"
        style={{
          backgroundImage:
            "url(images/home/bg-left.png), url(images/home/bg-right.png)",
          backgroundPosition: "left top, right bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          marginTop: "150px",
          paddingTop: "20px",
        }}
      >
        <h2
          style={{
            color: "#FFAB2D",
            textAlign: "center",
            fontSize: "32px",
            margin: "40px auto",
          }}
        >
          Great&nbsp;Things Coming&nbsp;Soon!
        </h2>
        <img
          src="images/home/img.png"
          alt="Image of a triangle stacked on top of a square, symbolizing the creation of a building."
          style={{
            width: "175px",
            margin: "60px auto",
            display: "block",
          }}
        />
        <p
          style={{
            color: "#242222",
            textAlign: "center",
            fontSize: "20px",
            fontWeight: 700,
          }}
        >
          Our website is under construction.
          <br />
          It&apos;s going to be amazing! Stay tuned!
        </p>
        <img
          src="images/logo.png"
          alt="Branding Image of Phonebox"
          style={{
            width: "100px",
            display: "block",
            margin: "80px auto 0",
            paddingBottom: "16px",
          }}
        />
      </section>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
