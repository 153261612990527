import React, { useState } from "react";
import styled from "styled-components";
import { Image, Card } from "react-bootstrap";
import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Crisp } from "crisp-sdk-web";

interface AppSupportCardGroupProps {
  group: any;
}

const AppSupportCardGroup: React.FC<AppSupportCardGroupProps> = (
  props: AppSupportCardGroupProps
) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <hr className="my-0 py-0" />
      <div
        className="d-flex justify-content-between align-items-center px-3 my-2"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      >
        <p className="text-left fs-6 fw-bold mb-0">{props.group.title}</p>
        {open && <FontAwesomeIcon icon={faClose} />}
      </div>
      {open && (
        <div>
          {props.group.topics.map((t, i: number) => {
            return (
              <div key={i}>
                <hr className="m-0" />
                <div className="px-3 py-1">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      Crisp.chat.openHelpdeskArticle(
                        "en",
                        t.articleId,
                        t.title
                      );
                    }}
                  >
                    <p
                      className="m-0"
                      style={{ cursor: "pointer", fontSize: "14px" }}
                    >
                      {t.title}
                    </p>
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AppSupportCardGroup;
