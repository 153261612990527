import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PageLayout from "../components/PageLayout";
import { Container, Row, Col, Image } from "react-bootstrap";
import CrispArticleCategory from "../components/Crisp/CrispArticleCategory";
import { mapBoxOnePlans } from "../utilities/utils";

// Redux
import { RootState } from "../redux/reducers/rootReducer";
import { useSelector } from "react-redux";
import { api } from "../utilities/api";
import PlanSelectList from "../components/PlanSelectList";

const supportArrayLeft = ["Sign Up", "eSIM"];
const supportArrayRight = ["Network/Technical Support"];

const PlansWithPromo = () => {
  const [plans, setPlans] = useState([]);

  const shop = useSelector((state: RootState) => {
    return state.shop;
  });

  const SupportTabsLeft = () => {
    return supportArrayLeft.map((item: any, index: number) => (
      <div key={index}>
        <CrispArticleCategory itemName={item} />
      </div>
    ));
  };

  const SupportTabsRight = () => {
    return supportArrayRight.map((item: any, index: number) => (
      <div key={index}>
        <CrispArticleCategory itemName={item} />
      </div>
    ));
  };

  useEffect(() => {
    const planType = shop.isPostpaid ? "Postpaid" : "Prepaid";
    api.getPlans(null, shop.promocode, shop.currency, planType).then((res) => {
      setPlans(mapBoxOnePlans(res.data, planType));
    });
  }, []);

  return (
    <PageLayout>
      <Container>
        <Section>
          <BannerImg src="/images/Banner.png" alt="PhoneBox promotion" />
          <NoticeArea>
            <NoticeDiv>
              <NoticeImg img="/images/Hand.png" />
              <NoticeDescripsion>
                <NoticeTitle>SIM Cards</NoticeTitle>
                <div>Already have a SIM card?</div>
                <div>
                  <span>You can activate it here!</span>
                </div>
              </NoticeDescripsion>
            </NoticeDiv>
          </NoticeArea>
          <Row className="mb-5">
            <PlanIntro>
              <div>
                <Title>Our Plans</Title>
                <p>
                  Choose the plan that suits your needs! <br />
                  You can choose the eSIM for more flexibility. It's faster and
                  easier.
                </p>
              </div>
              <ESIMPromot>
                <b>Choose eSIM and get your service starting now! </b>
                <Image src="/images/Fireworks.png" style={{ width: "30px" }} />
              </ESIMPromot>
            </PlanIntro>
          </Row>
          <PlanSelectList
            currency={shop.currency}
            country={shop.country}
            planType={shop.isPostpaid ? "Postpaid" : "Prepaid"}
            enableESIM={true}
            enableShipping={true}
            plans={plans}
          />

          <Title>Learn More</Title>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <SupportTabsLeft />
            </Col>
            <Col sm={12} md={12} lg={6}>
              <SupportTabsRight />
            </Col>
          </Row>
        </Section>
      </Container>
    </PageLayout>
  );
};

export default PlansWithPromo;

const NoticeArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const NoticeTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
`;
const NoticeDescripsion = styled.div`
  flex: 1;
  div {
    text-align: left;
  }
  span {
    font-size: 10px;
  }
  padding-left: 20px;
`;
const NoticeDiv = styled.div`
  display: flex;
  align-items: center;
`;
const NoticeImg = styled.div<{ img: any }>`
  width: 80px;
  height: 80px;
  background: url(${(props) => props.img}) no-repeat;
  background-size: contain;
`;

const Title = styled.p`
  font-size: 200%;
  font-weight: 650;
  margin-bottom: 10px;
`;

const Section = styled.div`
  padding: 30px 8% 0px 8%;
`;

const BannerImg = styled.img`
  width: 100%;
`;

const PlanTitle = styled.div`
  display: flex;
  width: 50%;
  height: 50px;
  border-radius: 10px;
  background-color: #ecfaff;
  justify-constant: spack-between;
  margin: 20px 0px 20px 0px;
  align-items: center;
  h3 {
    font-size: 17px;
    text-align: center;
    height: 100%;
    line-height: 40px;
    color: #0494ca;
  }
  div {
    flex: 1;
    height: 70%;
    border-right: 2px solid #00bbee;
  }
  p {
    flex: 1;
    padding: 13px 30px 0px 30px;
    color: #0494ca;
  }
  cursor: pointer;
`;

const ESIMPromot = styled.div`
  margin-top: 60px;
  width: 40%;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  height: 50px;
  border-radius: 10px;
  color: white;
  padding: 12px 0px 0px 30px;
  font-size: 12px;
`;

const PlanIntro = styled.div`
  display: flex;
  justify-content: space-between;
`;
