import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PageLayout from "../../components/PageLayout";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { saveRouteDetails } from "../../redux/actions/routeActions";
import { useI18next } from "gatsby-plugin-react-i18next";
import { UnderlineTitleBlack } from "../../components/StyledComponentsAll";
import { Trans } from "react-i18next";
import { graphql } from "gatsby";
import PlanSwitchRadio from "../../components/PlanSwitchRadio";
import { constants } from "../../redux/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faClose } from "@fortawesome/free-solid-svg-icons";
import AppSupportCard from "../../components/AppSupportCard";
import {
  CANADA_SUBJECTS,
  USA_SUBJECTS,
} from "../../utilities/support-constants";
import { goTo } from "../../utilities/utils";
import { SearchableArticle } from "../../types/types";
import { Crisp } from "crisp-sdk-web";
import { searchableArticles } from "../../constants/crisp-articles";

export default function support() {
  const dispatch = useDispatch();
  const { language } = useI18next();

  const [signupOpen, setSignupOpen] = useState(false);
  const [generalInfoOpen, setGeneralInfoOpen] = useState(false);
  const [managingYourAccountOpen, setManagingYourAccountOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [billingAndPaymentOpen, setBillingAndPaymentOpen] = useState(false);
  const [supportOpen, setSupportOpen] = useState(false);

  React.useEffect(() => {
    fromUSLinkHandle();
    dispatch(saveRouteDetails("support") as any);
  }, []);

  const [country, setCountry] = useState(constants.CANADA);
  const [search, setSearch] = useState("");
  const [articleList, setArticleList] = useState<SearchableArticle[]>([]);

  const fromUSLinkHandle = () => {
    // Find plans from USA
    let support = new URLSearchParams(window.location.search).get("support");

    if (support && support.toLowerCase() === "us") {
      setCountry(constants.USA);
    } else {
      return;
    }
  };

  const onSwitchCountry = (country) => {
    setCountry(country);
  };

  const clearSearch = () => {
    if (search.length > 0) {
      setSearch("");
    }
  };

  useEffect(() => {
    if (!search || search.length < 3) {
      return setArticleList([]);
    }
    const searchStringArray = search
      .toLowerCase()
      .split(" ")
      .filter(
        (a, i, searchableStringArray) =>
          a && searchableStringArray.indexOf(a) === i
      );
    type FilteredArticle = SearchableArticle & {
      matchCount: number;
    };
    const filteredArticles: FilteredArticle[] = searchableArticles
      .map((a) => {
        const match = searchStringArray.filter((word) => {
          const regex = new RegExp(word, "i");
          return a.searchableStringArray.some((str) => regex.test(str)); // Match any part of the string
        }).length;
        return { ...a, matchCount: match };
      })
      .sort((a, b) => b.matchCount - a.matchCount)
      .filter((a) => a.matchCount > 0);
    setArticleList(filteredArticles);
  }, [search]);

  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 769) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  return (
    <PageLayout>
      <title>Support | PhoneBox</title>
      <Container>
        <div className="position-relative">
          <Image
            style={{
              position: "absolute",
              top: 0,
              width: "100%",
              zIndex: -999,
              minHeight: "377px",
            }}
            src={
              country == constants.CANADA
                ? "/images/support/support_background_canada.png"
                : "/images/support/support_background_us.png"
            }
          />
          <div className="p-5 my-5">
            <div className="d-flex justify-content-center">
              <PlanSwitchRadio
                onChangeValue={onSwitchCountry}
                country={country}
              />
            </div>
            <div>
              <div className="my-3 text-center">
                <div className="fs-2 fw-bold" style={{ lineHeight: 1.2 }}>
                  What are you looking for?
                </div>
                <div
                  className="fs-5"
                  style={{ lineHeight: 1.2, paddingTop: "0.5rem" }}
                >
                  Type in the search bar
                  {/* or browse the categories */}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-center position-relative">
                <input
                  value={search}
                  autoComplete="off"
                  type="text"
                  className="form-control"
                  placeholder="Invoice, Billing..."
                  onChange={(e) => setSearch(e.target.value)}
                  style={{
                    height: "64px",
                    border: "none",
                    boxShadow: "0px 3px 6px #00000029",
                    borderRadius: "7px",
                    width: `${mobile ? "100%" : "80%"}`,
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    right: `${mobile ? "0%" : "10%"}`,
                    marginRight: "20px",
                    cursor: search.length > 0 ? "pointer" : "none",
                  }}
                  onClick={clearSearch}
                >
                  <FontAwesomeIcon
                    icon={search.length > 0 ? faClose : faSearch}
                    style={{ fontSize: "22px" }}
                    color="#6c757d"
                  />
                </div>
                {search && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 9999999,
                      width: "80%",
                      maxHeight: "400px",
                      border: "1px solid lightgray",
                      backgroundColor: "white",
                      borderRadius: "7px",
                      top: "74px",
                      overflow: "auto",
                    }}
                  >
                    {articleList.length ? (
                      articleList.map((a) => (
                        <p
                          key={a.articleId}
                          className="mb-1 p-2 "
                          style={{ cursor: "pointer", fontSize: "14px" }}
                          onClick={() => {
                            Crisp.chat.openHelpdeskArticle(
                              "en",
                              a.articleId,
                              a.title
                            );
                            setSearch("");
                          }}
                        >
                          {a.title}
                        </p>
                      ))
                    ) : search.length > 2 ? (
                      <p
                        className="mb-1"
                        style={{ cursor: "pointer", fontSize: "14px" }}
                      >
                        No results found.
                      </p>
                    ) : null}
                  </div>
                )}
              </div>
              <span className="d-block mt-1 text-center">
                Type at least 3 characters to get search results
              </span>
            </div>
          </div>
        </div>
        {language !== "en" && (
          <Row className="mb-4 text-center">
            <Col>
              <p className="fs-5 fw-bold" style={{ color: "red" }}>
                <Trans>
                  Sorry for the inconvenience. This page does not support
                  multiple languages.
                </Trans>
              </p>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12} sm={6} md={4}>
            <AppSupportCard
              subject={
                country === constants.CANADA
                  ? CANADA_SUBJECTS.beforeSignUp
                  : USA_SUBJECTS.beforeSignUp
              }
              open={signupOpen}
              onClick={() => {
                console.log("signupOpen");
                setSignupOpen(!signupOpen);
                setGeneralInfoOpen(false);
                setManagingYourAccountOpen(false);
                setServicesOpen(false);
                setBillingAndPaymentOpen(false);
                setSupportOpen(false);
              }}
            ></AppSupportCard>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <AppSupportCard
              subject={
                country === constants.CANADA
                  ? CANADA_SUBJECTS.generalInformation
                  : USA_SUBJECTS.generalInformation
              }
              open={generalInfoOpen}
              onClick={() => {
                console.log("generalInfoOpen");
                setSignupOpen(false);
                setGeneralInfoOpen(!generalInfoOpen);
                setManagingYourAccountOpen(false);
                setServicesOpen(false);
                setBillingAndPaymentOpen(false);
                setSupportOpen(false);
              }}
            ></AppSupportCard>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <AppSupportCard
              subject={
                country === constants.CANADA
                  ? CANADA_SUBJECTS.managingYourAccount
                  : USA_SUBJECTS.managingYourAccount
              }
              open={managingYourAccountOpen}
              onClick={() => {
                console.log("managingYourAccountOpen");
                setSignupOpen(false);
                setGeneralInfoOpen(false);
                setManagingYourAccountOpen(!managingYourAccountOpen);
                setServicesOpen(false);
                setBillingAndPaymentOpen(false);
                setSupportOpen(false);
              }}
            ></AppSupportCard>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <AppSupportCard
              subject={
                country === constants.CANADA
                  ? CANADA_SUBJECTS.services
                  : USA_SUBJECTS.services
              }
              open={servicesOpen}
              onClick={() => {
                console.log("servicesOpen");
                setSignupOpen(false);
                setGeneralInfoOpen(false);
                setManagingYourAccountOpen(false);
                setServicesOpen(!servicesOpen);
                setBillingAndPaymentOpen(false);
                setSupportOpen(false);
              }}
            ></AppSupportCard>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <AppSupportCard
              subject={
                country === constants.CANADA
                  ? CANADA_SUBJECTS.billingAndPayment
                  : USA_SUBJECTS.billingAndPayment
              }
              open={billingAndPaymentOpen}
              onClick={() => {
                console.log("billingAndPaymentOpen");
                setSignupOpen(false);
                setGeneralInfoOpen(false);
                setManagingYourAccountOpen(false);
                setServicesOpen(false);
                setBillingAndPaymentOpen(!billingAndPaymentOpen);
                setSupportOpen(false);
              }}
            ></AppSupportCard>
          </Col>
          <Col xs={12} sm={6} md={4}>
            <AppSupportCard
              subject={
                country === constants.CANADA
                  ? CANADA_SUBJECTS.support
                  : USA_SUBJECTS.support
              }
              open={supportOpen}
              onClick={() => {
                console.log("supportOpen");
                setSignupOpen(false);
                setGeneralInfoOpen(false);
                setManagingYourAccountOpen(false);
                setServicesOpen(false);
                setBillingAndPaymentOpen(false);
                setSupportOpen(!supportOpen);
              }}
            ></AppSupportCard>
          </Col>
        </Row>
      </Container>
      <Container
        id="contact-us"
        style={{
          scrollPaddingTop: "500px",
        }}
      >
        <UnderlineTitleBlack className="my-4">Contact Us</UnderlineTitleBlack>
        <Row>
          <Col sm={12} md={6}>
            <ContactWrapper>
              <Image src="/images/support/mail.png" width={30} height={30} />
              <div style={{ lineHeight: 0.5, marginLeft: 10 }}>
                <p style={{ fontWeight: "bold" }}>
                  <Trans>Email</Trans>:
                </p>
                <p>services@gophonebox.com</p>
              </div>
            </ContactWrapper>
          </Col>
          <Col sm={12} md={6}>
            <ContactWrapper>
              <Image src="/images/support/phone.png" width={30} height={30} />
              <div style={{ lineHeight: 0.5, marginLeft: 10 }}>
                <p style={{ fontWeight: "bold" }}>
                  <Trans>Phone Number</Trans>:
                </p>
                <p>+1-855-886-0505</p>
              </div>
            </ContactWrapper>
          </Col>
        </Row>
        <ContactWrapper>
          <Image src="/images/support/location.png" width={30} height={30} />
          <div style={{ marginLeft: 10 }}>
            <p style={{ fontWeight: "bold" }}>
              <Trans>Visit our stores</Trans>
            </p>
            <p style={{ marginBottom: 8 }}>
              <b>Toronto</b> 1240 Bay St. #305, Toronto, ON M5R 2A7
            </p>
            <p>
              <b>Vancouver</b> 658 Seymour St, Vancouver, BC V6B 3K4
            </p>
          </div>
        </ContactWrapper>
      </Container>
      <Container>
        <ReportConcernContainer>
          <UnderlineTitleBlack>
            <Trans>Share Your Feedback or Report a Concern</Trans>
          </UnderlineTitleBlack>
          <p
            style={{
              marginLeft: "0.6rem",
            }}
          >
            <Trans>
              Have a concern or issue you'd like assistance with? We're ready to
              help.
            </Trans>{" "}
            <ReportConcernAnchorTag
              href="/support/concern#report-a-concern"
              onClick={(e) => {
                e.preventDefault();
                goTo("/support/concern#report-a-concern");
              }}
            >
              <Trans>Submit your request here</Trans>
            </ReportConcernAnchorTag>
            .
          </p>
          <UnderlineTitleBlack>
            <Trans>Looking for Additional Support?</Trans>
          </UnderlineTitleBlack>
          <p
            style={{
              marginLeft: "0.6rem",
            }}
          >
            <Trans>
              We're here to listen. Explore more ways to share your feedback or
              address any challenges you're facing.
            </Trans>{" "}
            <ReportConcernAnchorTag
              href="/support/concern"
              onClick={(e) => {
                e.preventDefault();
                goTo("/support/concern");
              }}
            >
              <Trans>Click here to view more options</Trans>
            </ReportConcernAnchorTag>
          </p>
        </ReportConcernContainer>
      </Container>
    </PageLayout>
  );
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const ContactWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
`;

const ReportConcernContainer = styled.div`
  margin-top: 20px;
`;

const ReportConcernAnchorTag = styled.a`
  text-decoration: underline !important;
  color: #0494cb;
  font-weight: 500;
`;
