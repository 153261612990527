import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Image } from "react-bootstrap";
import PageLayout from "../components/PageLayout";
import { Container, Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import { graphql, Script, navigate } from "gatsby";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/reducers/rootReducer";
import { updateShopObject } from "../redux/actions/shopActions";
import { constants } from "../redux/constants";

const PaymentThankyou = () => {
  let shopDetails = useSelector((state: RootState) => state.shop);
  const dispatch = useDispatch();
  const dispatchShop = (obj) => dispatch(updateShopObject(obj) as any);
  const isCanada = shopDetails.country === constants.CANADA;

  const [complete, setComplete] = useState<boolean>(false);

  useEffect(() => {
    if (!shopDetails.paymentMade) {
      navigate("/");
      return;
    }
    dispatchShop({ paymentMade: false });
    // Wait for GTM to load if it hasn't loaded yet
    let timeout = null;
    const interval = setInterval(() => {
      // @ts-ignore
      if (window.dataLayer && window.google_tag_manager) {
        clearInterval(interval);
        timeout = setTimeout(() => {
          doDataLayerPush();
        }, 1000);
      }
    }, 100); // Check every 100ms

    // do dataLayer push
    const doDataLayerPush = () => {
      /**
       * This is the old implementation.
       * The logic has been updated to modify the time at which the event is fired.
       * The same triggers and events are being used as before.
       */
      // @ts-ignore
      window.dataLayer.push({
        event: "purchase",
        value: shopDetails.planId,
        transaction_id: shopDetails.oa_paId,
        affiliation: "none",
        currency: shopDetails.currency,
        tax: "see order details",
        shipping: shopDetails.sfName,
        coupon: !isCanada
          ? shopDetails.usPromocode
          : shopDetails.isPostpaid
          ? shopDetails.promocode
          : shopDetails.prepaidPromocode,
        ecommerce: {
          items: [
            {
              item_name: shopDetails.data,
              item_id: shopDetails.planId,
              price: shopDetails.plan_fee,
              item_category: !isCanada
                ? "USA Prepaid"
                : shopDetails.isPostpaid
                ? "Canada Postpaid"
                : "Canada Prepaid",
              item_variant: shopDetails.planTypeD,
              quantity: 1,
              index: 0,
            },
          ],
        },
      });
      setComplete(true);
    };
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      clearInterval(interval);
    };
  }, []);

  return (
    <PageLayout>
      <Container>
        <Row>
          <Col>
            <div style={{ textAlign: "center", paddingTop: "80px" }}>
              <Title>
                {complete ? (
                  <Trans>Your Activation is Completed!</Trans>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <span
                      className="fa-spin"
                      style={{
                        border: "8px solid #0494cb",
                        borderTop: "8px solid #0494",
                        borderBottom: "8px solid #0494",
                        width: "40px",
                        height: "40px",
                        display: "block",
                        borderRadius: "40px",
                      }}
                    ></span>
                    <Trans>Please wait a moment.</Trans>
                  </div>
                )}
              </Title>
              <PaddedImage
                width={"100px"}
                src="/images/checkout_Success.png"
                alt={"successful payment"}
              />
              <p>
                <Trans>
                  Thank you for choosing PhoneBox as your wireless service
                  provider.
                </Trans>
              </p>
              <p>
                <Trans>
                  We have sent you an email with your account and payment
                  information. Please check the email for more details.
                </Trans>
              </p>
              <p>
                <Trans>
                  Your Phone Number will be provided via Email and SMS once the
                  sim card is activated.
                </Trans>
              </p>
            </div>
            {complete ? (
              <CustomButton
                onClick={() => {
                  navigate("/");
                }}
              >
                <span className="ms-2">
                  <Trans>Go to Homepage</Trans>
                </span>
              </CustomButton>
            ) : null}
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
};

export default PaymentThankyou;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const PaddedImage = styled(Image)`
  padding-top: 20px;
  padding-bottom: 30px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 25px;
  text-align: center;
  height: 100%;
  line-height: 40px;
  color: #0494ca;
`;

const CustomButton = styled.button`
  display: block;
  font-size: 18px;
  font-weight: 400;
  transition: 0.7s;
  color: #fff;
  outline: none !important;
  padding: 8px 40px;
  border-radius: 50px;
  cursor: pointer;
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  background-image: linear-gradient(to bottom, #0194e9, #35c1d0);
  border: none;
  margin: 24px auto;
  :hover {
    color: #fff;
  }
`;

export const Head = () => {
  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          function scriptExists() {
            return document.querySelectorAll("script[src='https://www.googletagmanager.com/gtm.js?id=GTM-TJKD8FQ']").length > 0;
          }

          if(!scriptExists()) {
            console.log("does not exist. injecting...");
            (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' }); var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window, document, 'script', 'dataLayer', 'GTM-TJKD8FQ');
          } 

          var _dcq = _dcq || [];
          var _dcs = _dcs || {};
          _dcs.account = '8316321';
        
          (function() {
            var dc = document.createElement('script');
            dc.type = 'text/javascript'; dc.async = true;
            dc.src = '//tag.getdrip.com/8316321.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(dc, s);
          })();
        `,
        }}
      />
    </>
  );
};
