import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateShopObject } from "../redux/actions/shopActions";
import { RootState } from "../redux/reducers/rootReducer";
import { constants } from "../redux/constants";
import * as styles from "./css/PlanSelectCard.module.scss";
import ClickButton from "./ClickButton";
import BlackFridayDealsPill from "./BlackFriday/BlackFridayDealsPill";
import BlackFridayDataSlash from "./BlackFriday/BlackFridayDataSlash";
import { planIncludesDescription } from "../utilities/constants";
import PriceDisplay from "./PriceDisplay";
import { formatPhoneData, goTo } from "../utilities/utils";
import DataSlash from "./DataSlash";
import { Crisp } from "crisp-sdk-web";

interface PlanSelectCardProps {
  id: number;
  price: number;
  data: string;
  carrier: string;
  isPromotion: boolean;
  currency: string;
  planTypeD: string;
  path: string;
  planType: string;
  country: string;
  enableESIM?: boolean;
  enableShipping?: boolean;
  blackFriday?: boolean;
  blackFridayOriginalDataAmount?: number;
  dataSlashAmount?: number;
  plintronPlanId: number;
  subscriptionId: number;
}

const PlanSelectCard: React.FC<PlanSelectCardProps> = (
  props: PlanSelectCardProps
) => {
  const dispatch = useDispatch();
  const dispatchShop = (obj) => dispatch(updateShopObject(obj) as any);
  const planDetails = useSelector((state: RootState) => state.plans);
  const isShortTerm = props.planTypeD !== "30" && props.planTypeD;
  const shopDetails = useSelector((state: RootState) => state.shop);
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();
  const [mobile, setMobile] = useState(false);
  const [buttonWidth, setButtonWidth] = useState("150px");
  const [specialFontSize, setSpecialFontSize] = useState("");
  const [noWrap, setNoWrap] = useState(false);
  const [mobileShow, setMobileShow] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth < 360) {
        setButtonWidth("110px");
        setSpecialFontSize("24px");
        setNoWrap(true);
        setMobileShow(true);
      } else {
        setButtonWidth("150px");
        setSpecialFontSize("");
        setNoWrap(false);
        setMobileShow(false);
      }
      if (window.innerWidth < 992) {
        setMobile(true);
      } else setMobile(false);
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [mobile]);

  const onSIMClick = () => {
    dispatchShop({ planId: props.id });
    dispatchShop({ currency: props.currency });
    dispatchShop({ data: props.data });
    dispatchShop({ plan_fee: props.price });
    dispatchShop({ country: props.country });
    dispatchShop({
      currency:
        props.country === constants.USA
          ? constants.USA_CURRENCY
          : constants.CANADA_CURRENCY,
    });
    dispatchShop({ planCarrier: props.carrier });
    dispatchShop({ shipping_country: props.country });
    dispatchShop({ planTypeD: props.planTypeD });
    dispatchShop({ isPostpaid: props.planType === constants.POSTPAID_PLAN });
    dispatchShop({ dataOnly: props.planType === constants.DATA_ONLY_PLAN });
    dispatchShop({ editMode: false });
    if (props.country === constants.CANADA) {
      const plans =
        props.planType === constants.PREPAID_PLAN
          ? planDetails.Canada.Prepaid
          : planDetails.Canada.Postpaid;
      dispatchShop({ plans: plans });
    } else {
      const plans =
        props.planType === constants.PREPAID_PLAN
          ? planDetails.USA.Prepaid
          : planDetails.USA.Postpaid;
      dispatchShop({ plans: plans });
    }
  };

  const onESIMClick = () => {
    onSIMClick();
    dispatchShop({ esim: true });
    dispatchShop({ delivery_needed: false });
    if (props.plintronPlanId) {
      dispatchShop({ us_plintron_plan_id: props.plintronPlanId });
    }
    if (props.subscriptionId) {
      dispatchShop({ us_plan_i_subscription: props.subscriptionId });
    }
    goTo("/plan-info");
  };

  const onRegularSIMClick = () => {
    onSIMClick();
    dispatchShop({ esim: false });
    dispatchShop({ delivery_needed: props.enableShipping });
    if (props.plintronPlanId) {
      dispatchShop({ us_plintron_plan_id: props.plintronPlanId });
    }
    if (props.subscriptionId) {
      dispatchShop({ us_plan_i_subscription: props.subscriptionId });
    }
    goTo("/plan-info");
  };

  const getTimeUnit = (): string => {
    const days = props.planTypeD;
    if (days === "30") {
      return "month";
    } else if (days === "7") {
      return "week";
    } else if (days === "14") {
      return "2 weeks";
    } else {
      return "month";
    }
  };

  const itemObject = {
    Id: props.id,
    Price: props.price,
    Data: props.data,
    Carrier: props.carrier,
    Country: props.currency === "CAD" ? "Canada" : "USA",
    PlanTypeD: props.planTypeD,
    Path: props.path,
  };

  return (
    <div
      className={
        styles.planDetailContainer +
        " " +
        (isShortTerm ? styles.planBorder : "")
      }
    >
      {isShortTerm && (
        <div className={styles.verticalText}>
          <Trans>Short-term</Trans>
        </div>
      )}

      <div
        className={
          styles.planDetailCard +
          " " +
          (props.blackFriday ? styles.blackFridayPlanDetailCard : null)
        }
      >
        <div
          className={
            styles.planFirstRow +
            " " +
            (props.blackFriday ? styles.blackFridayPlanFirstRow : null)
          }
        >
          {isShortTerm && (
            <div className={styles.planPromo}>
              <Trans>Promo</Trans>
            </div>
          )}
          {props.data === "Unlimited" ||
          Number(props.data) > 1000 ||
          props.data === "20 GB" ? (
            <div className={styles.planTypeUnlimited}>Unlimited</div>
          ) : (
            <div
              className={
                styles.planType +
                " " +
                (props.blackFriday ? styles.blackFridayPlanType : null)
              }
            >
              <div
                style={{
                  marginTop: "7px",
                  position: "relative",
                  fontSize: specialFontSize,
                  whiteSpace: noWrap ? "nowrap" : "normal",
                }}
              >
                {props.blackFriday && (
                  <BlackFridayDealsPill
                    text={mobile ? "Holiday Sales" : "Holiday Sales"}
                  />
                )}
                {/* display data amount */}
                {props.data}
                {props.blackFriday && (
                  <BlackFridayDataSlash
                    data={props.blackFridayOriginalDataAmount}
                  />
                )}
                {props.dataSlashAmount !== null ? (
                  <DataSlash data={props.dataSlashAmount} />
                ) : null}
              </div>
            </div>
          )}
          <PriceDisplay
            price={props.price}
            currency={props.currency}
            timeUnit={getTimeUnit()}
            blackFriday={props.blackFriday}
          />
          <div
            className={
              styles.planSeeMoreInline +
              " " +
              (props.blackFriday ? styles.blackFridayPlanSeeMoreInline : null)
            }
          >
            <div
              onClick={() => {
                setShowDetails(!showDetails);
              }}
            >
              <Trans>{showDetails ? "See Less" : "Show More"}</Trans>
            </div>
          </div>
        </div>

        <div
          style={{
            display: mobileShow ? "block" : "none",
            textAlign: "center",
            padding: "4px",
            font: "normal normal normal 16px Poppins",
            cursor: "pointer",
          }}
          onClick={() => {
            setShowDetails(!showDetails);
          }}
        >
          <Trans>{showDetails ? "See Less" : "Show More"}</Trans>
        </div>

        {props.planType !== "Data" ? (
          <div
            className={
              styles.planDescription +
              " " +
              (props.blackFriday ? styles.blackFridayPlanDescription : null)
            }
          >
            <div
              className={
                styles.planItem +
                " " +
                (props.blackFriday ? styles.blackFridayPlanItem : null)
              }
            >
              <Trans>
                UNLIMITED {props.currency === "CAD" ? "Canada" : "USA"}-wide
                calling
              </Trans>
            </div>
            <div
              className={
                styles.planItem +
                " " +
                (props.blackFriday ? styles.blackFridayPlanItem : null)
              }
            >
              <Trans>UNLIMITED International texting</Trans>
            </div>
            {isShortTerm && (
              <div className={styles.promoTextItem}>
                7 <Trans>days</Trans>
              </div>
            )}
          </div>
        ) : (
          <div
            className={
              styles.planDescription +
              " " +
              (props.blackFriday ? styles.blackFridayPlanDescription : null)
            }
          >
            <div
              className={
                styles.planItem +
                " " +
                (props.blackFriday ? styles.blackFridayPlanItem : null)
              }
            >
              <Trans>High speed 5G network</Trans>
            </div>
            <div
              className={
                styles.planItem +
                " " +
                (props.blackFriday ? styles.blackFridayPlanItem : null)
              }
            >
              <Trans>Data top up available at $15 - 1GB</Trans>
            </div>
            <div
              className={
                styles.planItem +
                " " +
                (props.blackFriday ? styles.blackFridayPlanItem : null)
              }
            >
              <Trans>Auto-extension available</Trans>
            </div>
            {isShortTerm && (
              <div className={styles.promoTextItem}>
                7 <Trans>days</Trans>
              </div>
            )}
          </div>
        )}
        <div
          className={
            styles.buttonContainer +
            " " +
            (props.blackFriday ? styles.blackFridayButtonContainer : null)
          }
        >
          <div>
            {props.enableESIM !== false && (
              <ClickButton
                text="eSIM"
                color={"blue"}
                length={""}
                buttonLength={buttonWidth}
                onClick={onESIMClick}
              ></ClickButton>
            )}
          </div>
          <div>
            <ClickButton
              text="Regular SIM"
              color={"red"}
              length={"long"}
              buttonLength={buttonWidth}
              itemObject={itemObject}
              onClick={onRegularSIMClick}
            ></ClickButton>
          </div>
        </div>
        <div
          className={
            styles.seeMoreContainer +
            " " +
            (props.blackFriday ? styles.blackFridaySeeMoreContainer : null)
          }
        >
          <div
            onClick={() => {
              setShowDetails(!showDetails);
            }}
          >
            <Trans>{showDetails ? "See Less" : "Show More"}</Trans>
          </div>
        </div>
      </div>

      {showDetails && (
        <div className={styles.planDetailDescriptionView}>
          <div className="row">
            <div className="col-md-6 my-2">
              <div className={styles.HeadingDescription}>
                <Trans>Plan Includes:</Trans>
              </div>
              {props.planType === "Data" && (
                <div className={styles.contentMainDescription}>
                  <div>
                    <Trans>Supported on the Rogers Network</Trans>
                  </div>
                </div>
              )}
              {props.currency === "CAD" && props.planType !== "Data" && (
                <div className={styles.contentMainDescription}>
                  <div>
                    {formatPhoneData(props.data) +
                      " " +
                      t("high speed 5G LTE data")}
                  </div>
                  <div>
                    <Trans>UNLIMITED Canada-wide calling</Trans>
                  </div>
                  <div>
                    <Trans>
                      UNLIMITED International text and picture messaging
                    </Trans>
                  </div>
                  <div>
                    <Trans>Supported on the {props.carrier} Network</Trans>
                  </div>
                  <div>
                    <Trans>Call Display</Trans>
                  </div>
                  <div>
                    <Trans>Voicemail: Up to 35 minutes of messages</Trans>
                  </div>
                  <div>
                    <Trans>
                      Data {shopDetails.isPostpaid ? "Overage" : "TopUp"} Rate:
                      $15.00 / 1GB
                    </Trans>
                  </div>
                </div>
              )}
              {props.currency !== "CAD" && props.planType !== "Data" && (
                <div className={styles.contentMainDescription}>
                  {planIncludesDescription[props.id].map((entry, i) => {
                    if (entry.includes("75+ Countries")) {
                      let index = entry.indexOf("75+ Countries");
                      let firstPart = entry.substring(0, index);
                      return (
                        <div key={i}>
                          <Trans>{firstPart}</Trans>
                          <span
                            style={{
                              color: "#22C3F1",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              Crisp.chat.openHelpdeskArticle("en", "v4wb16");
                            }}
                          >
                            <Trans>75+ Countries</Trans>
                          </span>
                        </div>
                      );
                    }

                    return (
                      <div key={i}>
                        <Trans>{entry}</Trans>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="col-md-6 my-2">
              <div className={styles.HeadingDescription}>
                <Trans>Please Note:</Trans>
              </div>
              <div className={styles.contentMainDescription}>
                <div>
                  <Trans>Plan does not include International Roaming</Trans>
                </div>
                {props.country === "Canada" && (
                  <div>
                    <Trans>
                      Provincial 9-1-1 fees are extra (If applicable)
                    </Trans>
                  </div>
                )}
                {props.planType === "Data" && (
                  <div>
                    <Trans>Calling & Texting services are not available</Trans>
                  </div>
                )}
                {shopDetails.simcard_no === "" && (
                  <Trans>A SIM card fee of $10 will be charged</Trans>
                )}
                {props.id === 7 && (
                  <div>
                    <Trans>
                      International calling available as an add-on for $10
                    </Trans>
                  </div>
                )}
                {props.country === "USA" && (
                  <div>
                    <Trans>Data Top-up rates: $10/GB</Trans>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanSelectCard;
