import { constants } from "../redux/constants";

export const CANADA_SUBJECTS = {
    beforeSignUp: {
        title: 'Before Sign Up',
        imageSrc: '/images/support/account.svg',
        groups:
            [
                {
                    title: 'Get a Plan',
                    topics: [
                        { title: 'Which plans are available at PhoneBox?', articleId: '17wi7eg', country: constants.CANADA },
                        { title: 'PhoneBox plans have a lock-in contract?', articleId: '85hmjl', country: constants.CANADA },
                        { title: 'How does a Prepaid Plan work?', articleId: 'urn3qr', country: constants.CANADA },
                        { title: 'Does a prepaid line expire?', articleId: 'hk3tuw', country: constants.CANADA },
                        { title: 'How does a Monthly Plan work?', articleId: '11pau8', country: constants.CANADA },
                        { title: 'What is the difference between prepaid and monthly accounts?', articleId: '1qmv2gc', country: constants.CANADA },
                        { title: 'Is my device compatible with PhoneBox network?', articleId: 'jerkdp', country: constants.CANADA },
                        { title: 'Can I sign up for a PhoneBox plan if I am under 18 years old?', articleId: 'zdv1hc', country: constants.CANADA },
                        { title: 'Why should I choose PhoneBox over other mobile providers?', articleId: 'fom8xu', country: constants.CANADA },
                    ]
                },
                {
                    title: 'SIM Card',
                    topics: [
                        { title: 'How can a traveler who just arrived in Canada purchase a SIM card?', articleId: 'srg8fx', country: constants.CANADA },
                        { title: 'Is it possible to purchase a SIM card outside of Canada?', articleId: 'uwahhi', country: constants.CANADA },
                        { title: 'Does PhoneBox offer shipping of SIM cards?', articleId: '3u1wxh', country: constants.CANADA },
                        { title: 'Is it possible to schedule the activation date of the SIM card?', articleId: '1nkix9k', country: constants.CANADA },
                        { title: 'How do I start using my PhoneBox SIM card?', articleId: '17jpm35', country: constants.CANADA },
                    ]
                },
                {
                    title: 'eSIM',
                    topics: [
                        { title: 'What is eSIM? ', articleId: 'qx8p3v', country: constants.CANADA },
                        { title: 'What plans are available with eSIM at PhoneBox? ', articleId: 'yp2nff', country: constants.CANADA },
                        { title: 'Are all devices compatible with eSIM?', articleId: 'k789oq', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Phone Number',
                    topics: [
                        { title: 'Can I choose my phone number when I sign up?', articleId: 'h60nr0', country: constants.CANADA },
                        { title: 'How will I know my phone number?', articleId: '1gfq3un', country: constants.CANADA },
                        { title: 'Can I port-in a landline phone number to PhoneBox?', articleId: 'd8spwc', country: constants.CANADA },
                    ]
                }
            ]
    },
    generalInformation: {
        title: 'General Information',
        imageSrc: '/images/support/plans.svg',
        groups:
            [
                {
                    title: 'Activation',
                    topics: [
                        { title: 'Can I change my service\'s start date with PhoneBox?', articleId: '54te1c', country: constants.CANADA },
                        { title: 'How does the online activation work?', articleId: 'v79a81', country: constants.CANADA },
                        { title: 'When will PhoneBox deliver my SIM card?', articleId: 'yjtldc', country: constants.CANADA },
                        { title: 'How does eSIM activation work? ', articleId: '1mj0l56', country: constants.CANADA },
                        { title: 'Can I port in my phone number from my previous mobile carrier after I already have my PhoneBox\'s account activated? ', articleId: '14u6f9e', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Data',
                    topics: [
                        { title: 'What is data overage fee and how does it work?', articleId: '10jjr6p', country: constants.CANADA },
                        { title: 'Will I get a notification if I exceed my data limit?', articleId: 'd9iq1r', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Plan Change',
                    topics: [
                        { title: 'Can I upgrade or downgrade my plan?', articleId: 'bhe70u', country: constants.CANADA },
                        { title: 'Is it possible to change the plan anytime?', articleId: '12xe2yw', country: constants.CANADA },
                        { title: 'Does PhoneBox charge a fee to change my plan?', articleId: '1urhbpa', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Termination',
                    topics: [
                        { title: 'How do I terminate my account?', articleId: '1iuzl9n', country: constants.CANADA },
                        { title: 'Can I schedule my account termination date?', articleId: '19m0j9q', country: constants.CANADA },
                        { title: 'Will I get a refund for unused services when I terminate my account?', articleId: 'rj0900', country: constants.CANADA },
                        { title: 'When will I get my refund after terminating my account?', articleId: 'ad7fca', country: constants.CANADA },
                        { title: 'Can I still receive calls and SMS after my prepaid plan has expired?', articleId: '7yge1d', country: constants.CANADA },
                        { title: 'Will I get a notification when my prepaid plan is about to expire?', articleId: '1f01a88', country: constants.CANADA },
                    ]
                },
            ]
    },
    managingYourAccount: {
        title: 'Managing your Account',
        imageSrc: '/images/support/twenty_four_seven.svg',
        groups:
            [
                {
                    title: 'Sign Up',
                    topics: [
                        { title: 'Register for an online account', articleId: '1yr4zl3', country: constants.CANADA },
                        { title: 'What are the services and options available on Self-Serve?', articleId: '1tqnfg9', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Extension',
                    topics: [
                        { title: 'How do I extend my service under the prepaid plan?', articleId: 'lzol70', country: constants.CANADA },
                        { title: 'I forgot to extend my prepaid plan before term ending date. How can I make a payment?', articleId: 'nqnrkp', country: constants.CANADA },
                        { title: 'Is it possible to set auto-extension for my prepaid plan?', articleId: '1rm53hz', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Data',
                    topics: [
                        { title: 'How do I add more data to my prepaid plan?', articleId: '10lec7p', country: constants.CANADA },
                        { title: 'How do I check my data usage?', articleId: 'ejqzx5', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Plan Change',
                    topics: [
                        { title: 'Change your plan', articleId: '1s0ewtl', country: constants.CANADA },
                        { title: 'How can I change my plan from prepaid to monthly?', articleId: '5kdy0h', country: constants.CANADA },
                        { title: 'How can I change my plan from monthly to prepaid?', articleId: '1egd15e', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Multiline',
                    topics: [
                        { title: 'What is Multiline Accounts?', articleId: 'qc7zgu', country: constants.CANADA },
                        { title: 'What are the benefits of using a Multi-lining account function?', articleId: '1ei36rx', country: constants.CANADA },
                        { title: 'How to add a line on your online account using the Multi-lining option? ', articleId: '206eg4', country: constants.CANADA },
                        { title: 'How to remove a line on your online account under the Multi-lining option? ', articleId: '1yp8th9', country: constants.CANADA },
                    ]
                }
            ]
    },
    services: {
        title: 'Services',
        imageSrc: '/images/support/services.svg',
        groups:
            [
                {
                    title: 'Payment Methods',
                    topics: [
                        { title: 'What are the payment methods for prepaid plans?', articleId: 'hv4it5', country: constants.CANADA },
                        { title: 'What are the payment methods for monthly plans?', articleId: 'pb40mf', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Coverage',
                    topics: [
                        { title: 'How good is the PhoneBox network coverage in Canada?', articleId: '1d8q5gn', country: constants.CANADA },
                        { title: 'Does PhoneBox offer international calling? ', articleId: '1sxexr4', country: constants.CANADA }, // no article id in spreadsheet, commented for now
                    ]
                },
                {
                    title: 'Vacation Plan',
                    topics: [
                        { title: 'What is a Vacation Plan and how does it work?', articleId: '1y0iy53', country: constants.CANADA },
                        { title: 'Can I extend my vacation plan?', articleId: 'np3r5p', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Home Internet',
                    topics: [
                        { title: 'Who is the Home Internet main provider?', articleId: '1w34qfy', country: constants.CANADA },
                        { title: 'How to know if I\'m eligible to sign up?', articleId: 'i1n3c6', country: constants.CANADA },
                        { title: 'What\'s the available coverage?', articleId: '8kqubx', country: constants.CANADA },
                        { title: 'Are there any restrictions when signing up for this Home Internet Plan?', articleId: 'qxjie5', country: constants.CANADA },

                    ]
                },
                {
                    title: 'International Calling',
                    topics: [
                        { title: 'Does PhoneBox offer International Calling?', articleId: '1sxexr4', country: constants.CANADA },
                        { title: 'How many countries can I call out to with my International calling plans?', articleId: 'v4wb16', country: constants.CANADA },
                    ]
                }
            ]
    },
    billingAndPayment: {
        title: 'Billing & Payment',
        imageSrc: '/images/support/billing_payment.svg',
        groups:
            [
                {
                    title: 'Declined Card',
                    topics: [
                        { title: 'PhoneBox notified me that my registered card was declined. What should I do?', articleId: '16v02k7', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Billing',
                    topics: [
                        { title: 'What is the billing cycle for prepaid plans?', articleId: '12aqtx1', country: constants.CANADA },
                        { title: 'What is the billing cycle for monthly plans?', articleId: '16rauq4', country: constants.CANADA },
                        { title: 'Register for an auto payment', articleId: '1t6lp6b', country: constants.CANADA },
                    ]
                },
            ]
    },
    support: {
        title: 'Support',
        imageSrc: '/images/support/support_information.svg',
        groups:
            [
                {
                    title: 'Shipping',
                    topics: [
                        { title: 'How can I track my SIM card delivery?', articleId: 'okmv45', country: constants.CANADA },
                        { title: 'Why have I not received my SIM card yet?', articleId: 'ekcciy', country: constants.CANADA },
                        { title: 'Why is my SIM card delivery delayed?', articleId: '1uez7ba', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Service Issues',
                    topics: [
                        { title: 'Troubleshooting options available on the mobile device.', articleId: 'lt4hds', country: constants.CANADA },
                        { title: 'My phone shows "No Service". ', articleId: '1c7l0is', country: constants.CANADA },
                        { title: 'I can\'t send text messages.', articleId: 'kwxoz6', country: constants.CANADA },
                        { title: 'Why can\'t I receive text messages?', articleId: '135olqo', country: constants.CANADA },
                        { title: 'I can\'t make outgoing calls.', articleId: 'obxapm', country: constants.CANADA },
                        { title: 'I can\'t receive calls', articleId: '1xcikkb', country: constants.CANADA },
                        { title: 'I can\'t log into my online account. It shows an error.', articleId: '1hyjii6', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Port Out',
                    topics: [
                        { title: 'How do I transfer my phone number to a different mobile service provider?', articleId: '15izugi', country: constants.CANADA },
                    ]
                },
                {
                    title: 'Customer Support Team',
                    topics: [
                        { title: 'How to reach out to PhoneBox Customer Support team? ', articleId: '1ifk481', country: constants.CANADA },
                        { title: 'Why there is no reply to my emails?', articleId: 'eyfxwf', country: constants.CANADA },
                    ]
                }
            ]
    }
};

export const USA_SUBJECTS = {
    beforeSignUp: {
        title: 'Before Sign Up',
        imageSrc: '/images/support/account.svg',
        groups:
            [
                {
                    title: 'Get a Plan',
                    topics: [
                        // { title: 'What is the PhoneBox\'s US prepaid plan?', articleId: 99, country: constants.USA },
                        // { title: 'How do I sign up for a US prepaid plan at PhoneBox?', articleId: 101, country: constants.USA },
                    ]
                },
                {
                    title: 'SIM Card',
                    topics: [
                        // { title: 'Is it possible to purchase a SIM card outside of the USA?', articleId: 198, country: constants.USA },
                        // { title: 'I don\'t have a SIM card yet. How do I sign up for a service with PhoneBox?', articleId: 184, country: constants.USA },
                    ]
                },
                {
                    title: 'Phone Number',
                    topics: [
                        { title: 'Can I choose my phone number when I sign up?', articleId: 'h60nr0', country: constants.USA },
                        // { title: 'Am I able to transfer my existing phone number to PhoneBox?', articleId: 128, country: constants.USA },
                    ]
                },
                {
                    title: 'Activation',
                    topics: [
                        // { title: 'How to activate my US prepaid plan?', articleId: 100, country: constants.USA },
                        // { title: 'How to activate PhoneBox service under eSIM? ', articleId: 183, country: constants.USA },
                    ]
                }
            ]
    },
    generalInformation: {
        title: 'General Information',
        imageSrc: '/images/support/plans.svg',
        groups:
            [
                {
                    title: 'Plan Change',
                    topics: [
                        // { title: 'What is the difference between plan change and term extension? ', articleId: 104, country: constants.USA },
                        // { title: 'If I request for a plan change, when will the new plan begin?', articleId: 121, country: constants.USA },
                        // { title: 'Is there a deadline for plan change requests?', articleId: 122, country: constants.USA },
                    ]
                },
                {
                    title: 'Termination',
                    topics: [
                        { title: 'Do I get a notification when my prepaid plan is about to expire?', articleId: '1f01a88', country: constants.USA },
                        { title: 'How do I terminate my account?', articleId: '1iuzl9n', country: constants.USA },
                        // { title: 'Am I able to remove my requested termination?', articleId: 127, country: constants.USA },
                        // { title: 'Am I able to reactivate my account after termination?', articleId: 126, country: constants.USA },
                        // { title: 'Can I continue using my SIM card after my term expires? ', articleId: 185, country: constants.USA },
                    ]
                },
            ]
    },
    managingYourAccount: {
        title: 'Managing your Account',
        imageSrc: '/images/support/twenty_four_seven.svg',
        groups:
            [
                {
                    title: 'Sign Up',
                    topics: [
                        // { title: 'How to Sign-Up to use Self-Serve?', articleId: 191, country: constants.USA },
                        { title: 'What are the services and options available on Self-Serve?', articleId: '1tqnfg9', country: constants.USA },
                    ]
                },
                {
                    title: 'Extension',
                    topics: [
                        // { title: 'How do I extend my service under the US prepaid plan?', articleId: 102, country: constants.USA },
                        { title: 'Can I pre-schedule my prepaid extension?', articleId: '1rm53hz', country: constants.USA },
                        { title: 'Can I set up auto-extension?', articleId: '1rm53hz', country: constants.USA },
                    ]
                },
                {
                    title: 'Data',
                    topics: [
                        // { title: 'How can I top up more data?', articleId: 108, country: constants.USA },
                        { title: 'How do I check my data usage?', articleId: 'ejqzx5', country: constants.USA },
                    ]
                },
                {
                    title: 'Plan Change',
                    topics: [
                        { title: 'How can I change my plan? ', articleId: '12xe2yw', country: constants.USA },
                        // { title: 'Am I able to cancel a requested plan change?', articleId: 125, country: constants.USA },
                    ]
                }
            ]
    },
    services: {
        title: 'Services',
        imageSrc: '/images/support/services.svg',
        groups:
            [
                {
                    title: 'Payment Methods',
                    topics: [
                        // { title: 'What are the accepted payment methods? ', articleId: 107, country: constants.USA },
                        { title: 'Can I use an international credit card to pay for a US phone number?', articleId: 'jq0qsy', country: constants.USA },
                    ]
                },
                {
                    title: 'Data',
                    topics: [
                        // { title: 'What happens if I reach the data limit?', articleId: 189, country: constants.USA },
                    ]
                },
                {
                    title: 'International Calling',
                    topics: [
                        { title: 'Does PhoneBox offer international calling? ', articleId: '1sxexr4', country: constants.USA },
                        { title: 'List of countries for International Calling ', articleId: 'v4wb16', country: constants.USA },
                        // { title: 'International Calling: calling minutes Top-Up', articleId: 170, country: constants.USA },
                    ]
                },
            ]
    },
    billingAndPayment: {
        title: 'Payment',
        imageSrc: '/images/support/billing_payment.svg',
        groups:
            [
                {
                    title: 'Payment Methods',
                    topics: [
                        // { title: 'What are the available payment methods for term extension? ', articleId: 187, country: constants.USA },
                        // { title: 'What are the payment methods available for data top up? ', articleId: 186, country: constants.USA },
                        { title: 'PhoneBox notified me that my registered card was declined. What should I do?', articleId: '16v02k7', country: constants.USA },
                    ]
                },
                {
                    title: 'Billing',
                    topics: [
                        // { title: 'Should I receive a monthly invoice under my prepaid plan? ', articleId: 106, country: constants.USA },
                        { title: 'I forgot to extend my services and my term ended. How can I make a payment?', articleId: 'nqnrkp', country: constants.USA },
                        { title: 'Am I able to set up automatic payments?', articleId: '1t6lp6b', country: constants.USA },
                        // { title: 'Can I get a refund on the data top up? ', articleId: 188, country: constants.USA },
                    ]
                },
            ]
    },
    support: {
        title: 'Support',
        imageSrc: '/images/support/support_information.svg',
        groups:
            [
                {
                    title: 'Shipping',
                    topics: [
                        // { title: 'What if I still have not received my SIM on the expected delivery date?', articleId: 137, country: constants.USA },
                    ]
                },
                {
                    title: 'Service Issues',
                    topics: [
                        { title: 'Troubleshooting options available on the mobile device.', articleId: 'lt4hds', country: constants.USA },
                        { title: 'My phone shows “No Service”. ', articleId: '1c7l0is', country: constants.USA },
                        { title: 'I can\'t send text messages.', articleId: 'kwxoz6', country: constants.USA },
                        { title: 'I can\'t make outgoing calls.', articleId: 'obxapm', country: constants.USA },
                        { title: 'I can\'t log into my online account. It shows an error.', articleId: '1hyjii6', country: constants.USA },
                    ]
                },
                {
                    title: 'Port Out',
                    topics: [
                        { title: 'How do I transfer my phone number to a different mobile service provider?', articleId: '15izugi', country: constants.USA },
                    ]
                },
                {
                    title: 'Customer Support Team',
                    topics: [
                        { title: 'How to reach out to PhoneBox Customer Support team? ', articleId: '1ifk481', country: constants.USA },
                        { title: 'Why there is no reply to my emails?', articleId: 'eyfxwf', country: constants.USA },
                    ]
                }
            ]
    }
};
